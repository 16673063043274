var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"450px"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[(_vm.model)?_c('v-form',{ref:"form",attrs:{"autocomplete":"on"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"rounded":""}},[_c('v-card-title',{staticClass:"primary--text"},[_vm._v(" Register Event "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.model = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-2 pb-2"},[_c('v-list',{staticClass:"pt-0",attrs:{"dense":""}},[_c('v-list-item',[_c('v-select',{attrs:{"label":"Meal Option","items":_vm.MEAL_OPTIONS,"rules":[
                function () { return !!_vm.trainingData.meal_option || 'Meal options is required'; }
              ]},model:{value:(_vm.trainingData.meal_option),callback:function ($$v) {_vm.$set(_vm.trainingData, "meal_option", $$v)},expression:"trainingData.meal_option"}})],1),(_vm.currentTraining.display_shirt_option)?_c('v-list-item',[_c('v-select',{attrs:{"label":"Shirt Size","items":_vm.SHIRT_SIZE,"rules":[
                function () { return !!_vm.trainingData.shirt_size || 'Shirt options is required'; }
              ]},model:{value:(_vm.trainingData.shirt_size),callback:function ($$v) {_vm.$set(_vm.trainingData, "shirt_size", $$v)},expression:"trainingData.shirt_size"}})],1):_vm._e(),(_vm.currentTraining.display_accomodation_option)?_c('v-list-item',[_c('v-select',{attrs:{"label":"Accomodation","items":_vm.ACCOMODATION,"rules":[
                function () { return !!_vm.trainingData.accomodation ||
                  'Accommodation options is required'; }
              ]},model:{value:(_vm.trainingData.accomodation),callback:function ($$v) {_vm.$set(_vm.trainingData, "accomodation", $$v)},expression:"trainingData.accomodation"}})],1):_vm._e(),(_vm.currentTraining.break_up_session)?_c('v-list-item',[_c('v-select',{attrs:{"label":"Breakout Session Preferred language","items":_vm.langList,"rules":[
                function () { return !!_vm.trainingData.pref_lang ||
                  'Preferred language options is required'; }
              ]},model:{value:(_vm.trainingData.pref_lang),callback:function ($$v) {_vm.$set(_vm.trainingData, "pref_lang", $$v)},expression:"trainingData.pref_lang"}})],1):_vm._e(),_c('v-list-item',{staticClass:"pt-2"},[_c('span',{staticClass:"caption",staticStyle:{"text-align":"justify"}},[_vm._v(" I declare that the information given herein are correct to the best of my knowledge and belief. I agree to be govern by the rules and regulations of PRULIA as they now exist as they may hereafter be altered. ")])]),_c('v-list-item',[_c('v-list-item-title',[_vm._v("I agree to the statement above")]),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"hide-details":"","rules":[
                  function () { return !!_vm.acknowledge || 'Acknowledgement options is required'; }
                ]},model:{value:(_vm.acknowledge),callback:function ($$v) {_vm.acknowledge=$$v},expression:"acknowledge"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"primary"},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"type":"submit","disabled":!_vm.valid,"loading":_vm.loading,"rounded":"","text":""}},[_vm._v("Register ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }